export default function backendInfo(){
    return [
        {
            id:"1",
            title:"Общие вопросы",
            main:[
                {
                    id:1,
                    title:"Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:2,
                    title:"Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:3,
                    title:"Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:4,
                    title:"Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:5,
                    title:"Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                }
            ]
        },
        {
            id:'2',
            title:"Эксплуатация и уход",
            main:[
                {
                    id:1,
                    title:"2-  Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:2,
                    title:"2-  Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:3,
                    title:"2-  Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:4,
                    title:"2-  Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                },
                {
                    id:5,
                    title:"2-  Как часто нужно менять щётки стеклоочистителя?",
                    main:"Мы привыкли эксплуатировать автомобиль, пока что-либо не сломается. Так и со щётками - не заменим, пока совсем не потеряем возможность видеть перед собой из-за грязного лобового стекла. Следите за своими щётками и меняйте их каждый сезон, ведь безопасность на дороге превыше всего!"
                }
            ]
        },
    ]

}